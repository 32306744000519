export const fontTypes = [
  'heading',
  'title',
  'subtitle',
  'subtitle-small',
  'paragraph',
  'paragraph-small',
  'button',
  'button-small',
  'monospace',
] as const

export type FontType = (typeof fontTypes)[number]

export const fontColors = [
  'primary',
  'secondary',
  'danger',
  'inherit',
  'brand',
  'white',
  'placeholder',
] as const

export type FontColor = (typeof fontColors)[number]

export const lookupFont = (fontKey: FontType): string => {
  switch (fontKey) {
    case 'heading':
      return 'text-heading-2'
    case 'title':
      return 'text-title-2'
    case 'subtitle':
      return 'text-subtitle-1'
    case 'subtitle-small':
      return 'text-subtitle-2'
    case 'button':
      return 'text-button-large'
    case 'button-small':
      return 'text-button'
    case 'paragraph-small':
      return 'text-small'
    case 'monospace':
      return 'text-mono'
    default:
      return 'text-paragraph'
  }
}

export const lookupFontColor = (fontColor: FontColor): string => {
  if (fontColor === 'inherit') return ''

  if (fontColor === 'brand') return 'misc-brand'

  if (fontColor === 'white') return 'generic-white'

  if (fontColor === 'danger') return 'status-danger'

  return `text-color-${fontColor}`
}
