<i18n locale="en">
{
  "labels": {
    "empty": "No options found"
  }
}
</i18n>

<template>
  <OwnCard class="own-dropdown-menu" control>
    <OwnStack v-if="props.items.length > 0" class="own-dropdown-menu__content">
      <OwnDropdownMenuItem
        v-for="item of props.items"
        :key="item.value"
        :active="props.active === item.value"
        :label="item.label"
        :color="item.color"
        :icon="item.icon"
        @click="onItemClick(item)"
      >
        <template #left>
          <slot name="left" :item="item" />
        </template>
        <template #right>
          <slot name="right" :item="item" />
        </template>
      </OwnDropdownMenuItem>
    </OwnStack>

    <OwnType
      v-else
      class="own-dropdown-menu__empty"
      :text="$tc('labels.empty')"
      variant="subtitle"
    />

    <slot name="actions" />
  </OwnCard>
</template>

<script lang="ts" setup generic="T extends Record<string, string | undefined>">
import { computed } from 'vue'

import { OwnCard } from '../../OwnCard'
import { OwnStack } from '../../OwnStack'
import { OwnType } from '../../OwnType'

import { OwnDropdownItem } from '../types'

import OwnDropdownMenuItem from './OwnDropdownMenuItem.vue'

const LINE_HEIGHT = 53
const PADDING_HEIGHT = 8

interface OwnDropdownMenuProps {
  active?: string
  items: (OwnDropdownItem & T)[]
  visibleOptions?: number
}

const props = withDefaults(defineProps<OwnDropdownMenuProps>(), {
  active: undefined,
  visibleOptions: 4,
})

type OwnDropdownMenuEmits = (event: 'selected', value: OwnDropdownItem) => void

const emit = defineEmits<OwnDropdownMenuEmits>()

const maxContentHeight = computed(() => {
  return `${LINE_HEIGHT * props.visibleOptions + PADDING_HEIGHT}px`
})

const onItemClick = (item: OwnDropdownItem) => {
  emit('selected', item)
}
</script>

<style lang="scss">
.own-dropdown-menu {
  padding: 4px;
  overflow: hidden;
  max-height: v-bind(maxContentHeight);

  &__content {
    overflow-y: auto;
  }

  &__empty {
    padding: 8px;
  }
}
</style>
