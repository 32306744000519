<template>
  <div ref="container" v-click-outside="onClickOutside" class="own-popover">
    <slot
      name="trigger"
      class="own-popover__trigger"
      :class="[grow && 'own-popover__trigger--grow']"
      :on="scopedTriggerProps"
      :open="showPopover"
      :close="closePopover"
    ></slot>
    <template v-if="useShow">
      <div
        v-show="showPopover"
        ref="content"
        class="own-popover__content"
        :style="contentStyle"
        @mouseleave="onContentMouseLeave"
      >
        <slot name="content" :close="closePopover"></slot>
      </div>
    </template>
    <template v-else>
      <div
        v-if="showPopover"
        ref="content"
        class="own-popover__content"
        :style="contentStyle"
        @mouseleave="onContentMouseLeave"
      >
        <slot name="content" :close="closePopover"></slot>
      </div>
    </template>
  </div>
</template>
<script>
import { clickOutside } from '../directives/clickOutside'

export default {
  name: 'OwnPopover',
  directives: { clickOutside },
  props: {
    /** Function executed after click outside triggers */
    afterClickOutside: {
      type: Function,
      default: undefined,
    },

    /** Alignment along x axis */
    alignX: {
      type: String,
      default: 'center',
      validator: (v) => ['left', 'right', 'center'].includes(v),
    },

    /** Alignment along y axis */
    alignY: {
      type: String,
      default: 'bottom',
      validator: (v) => ['top', 'bottom', 'center'].includes(v),
    },

    /** Height of the content block */
    contentHeight: {
      type: [Number, String],
      default: 70,
    },

    /** Offset for the content from its trigger */
    contentOffset: {
      type: [Number, String],
      default: 8,
    },

    /** Width of the content block */
    contentWidth: {
      type: [Number, String],
      default: 150,
    },

    /** z-index for content */
    elevation: {
      type: String,
      default: undefined,
    },

    /** Grow to container size */
    grow: {
      type: Boolean,
      default: false,
    },

    preventAutomaticClose: {
      type: Boolean,
      default: false,
    },

    /** Prioritize Axis */
    slide: {
      type: Array,
      default: undefined,
      validator: (v) => {
        const valid = ['x', 'y']
        return v
          .map((current) => valid.includes(current))
          .every((value) => value === true)
      },
    },

    /** Type of trigger */
    triggerType: {
      type: String,
      default: 'click',
      validator: (v) => ['click', 'hover'].includes(v),
    },

    /** Use Show */
    useShow: { type: Boolean, default: false },
  },
  data() {
    return {
      contentContainerWidth: 0,
      popoverLocation: {
        x: 0,
        y: 0,
      },
      showPopover: false,
    }
  },
  computed: {
    contentStyle() {
      const {
        contentHeight,
        contentContainerWidth,
        elevation,
        popoverLocation,
      } = this

      return {
        height: `${contentHeight}px`,
        left: `${popoverLocation.x}px`,
        top: `${popoverLocation.y}px`,
        width: `${contentContainerWidth}px`,
        zIndex: elevation,
      }
    },
    numericContentHeight() {
      const { contentHeight } = this

      return parseInt(contentHeight, 10)
    },
    numericContentOffset() {
      const { contentOffset } = this

      return parseInt(contentOffset, 10)
    },
    numericContentWidth() {
      const { contentWidth } = this

      return parseInt(contentWidth, 10)
    },
    scopedTriggerProps() {
      const { onClick, onMouseEnter, onMouseLeave, triggerType } = this

      if (triggerType === 'click') {
        return {
          click: onClick,
        }
      }

      return {
        mouseenter: onMouseEnter,
        mouseleave: onMouseLeave,
      }
    },
  },
  watch: {
    contentHeight() {
      this.setLocation()
    },
  },
  methods: {
    closePopover() {
      this.showPopover = false
    },
    getXCoordinate(triggerBox) {
      const { alignX, numericContentWidth, numericContentOffset, slide } = this

      switch (alignX) {
        case 'left':
          if (slide && slide.includes('x')) {
            return triggerBox.x - (numericContentWidth - triggerBox.width)
          }
          return triggerBox.x - numericContentWidth - numericContentOffset

        case 'right':
          if (slide && slide.includes('x')) {
            return triggerBox.x
          }
          return triggerBox.x + triggerBox.width + numericContentOffset

        default:
          if (triggerBox.width > numericContentWidth) {
            return (
              triggerBox.x + (triggerBox.width / 2 - numericContentWidth / 2)
            )
          } else {
            return (
              triggerBox.x - (numericContentWidth / 2 - triggerBox.width / 2)
            )
          }
      }
    },
    getYCoordinate(triggerBox) {
      const { alignY, numericContentHeight, numericContentOffset, slide } = this

      switch (alignY) {
        case 'top':
          if (slide && slide.includes('y')) {
            return triggerBox.y - (numericContentHeight - triggerBox.height)
          }
          return triggerBox.y - numericContentHeight - numericContentOffset

        case 'bottom':
          if (slide && slide.includes('y')) {
            return triggerBox.y
          }
          return triggerBox.y + triggerBox.height + numericContentOffset

        default:
          if (triggerBox.height > numericContentHeight) {
            return (
              triggerBox.y + (triggerBox.height / 2 - numericContentHeight / 2)
            )
          } else {
            return (
              triggerBox.y - (numericContentHeight / 2 - triggerBox.height / 2)
            )
          }
      }
    },
    onClick() {
      const { triggerType, showPopover } = this

      this.setLocation()
      this.setWidth()

      if (triggerType === 'click') {
        this.showPopover = !showPopover
      }
    },
    onClickOutside() {
      const { triggerType, afterClickOutside, preventAutomaticClose } = this

      if (preventAutomaticClose) return

      if (triggerType === 'click') {
        this.closePopover()
      }

      if (afterClickOutside) {
        afterClickOutside()
      }
    },
    onContentMouseLeave() {
      const { triggerType } = this
      if (triggerType === 'hover') {
        this.showPopover = false
      }
    },
    onMouseEnter() {
      const { triggerType } = this

      this.setLocation()
      this.setWidth()

      if (triggerType === 'hover') {
        this.showPopover = true
      }
    },
    onMouseLeave(mouseEvent) {
      const { triggerType } = this
      if (triggerType === 'hover') {
        if (this.$refs.content) {
          const { left, right, top, bottom } =
            this.$refs.content.getBoundingClientRect()

          const { clientX, clientY } = mouseEvent

          if (
            // Hovering over content...
            !(
              left < clientX &&
              right > clientX &&
              top < clientY &&
              bottom > clientY
            )
          ) {
            this.showPopover = false
          }
        } else {
          this.showPopover = false
        }
      }
    },
    setLocation() {
      const container = this.$refs.container

      if (container) {
        const parentContainerRect = container.getBoundingClientRect()

        this.popoverLocation.x = this.getXCoordinate(parentContainerRect)
        this.popoverLocation.y = this.getYCoordinate(parentContainerRect)
      } else {
        throw new Error('Unable to find Popover Container Instance in DOM')
      }
    },
    setWidth() {
      const { contentWidth, grow } = this
      const container = this.$refs.container

      if (container && grow) {
        this.contentContainerWidth = container.getBoundingClientRect().width
      } else {
        this.contentContainerWidth = contentWidth
      }
    },
  },
}
</script>
<style lang="scss">
.own-popover {
  &__trigger {
    display: inline-flex;
    width: 100%;
    height: 100%;

    &--grow {
      width: 100%;
    }
  }

  &__content {
    position: fixed;

    // Above EVERYTHING else
    z-index: 1000;
  }
}
</style>
