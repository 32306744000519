<template>
  <div class="own-chip">
    <p class="text-small text-color-secondary" v-text="text"></p>
    <button class="own-chip__remove-btn" @click="$emit('remove')">
      <PhX />
    </button>
  </div>
</template>

<script>
import { PhX } from 'phosphor-vue/dist/phosphor-vue.esm'

export default {
  name: 'OwnChip',
  components: {
    PhX,
  },
  props: {
    /**
     * Text to show
     */
    text: { type: String, required: true },
  },
}
</script>

<style lang="scss">
.own-chip {
  display: flex;
  flex-direction: row;

  padding: 2px 4px 0 4px;
  border-radius: 4px;
  background-color: $bluetiful-100;
  border: 1px solid $bluetiful-300;

  &__remove-btn {
    margin-left: 4px;
  }
}
</style>
