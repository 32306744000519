<template>
  <Responsive v-slot="{ width }">
    <!--  Loading  -->
    <OwnOption v-if="$slots['loading']" el="div">
      <slot name="loading"></slot>
    </OwnOption>

    <!--  No Data  -->
    <OwnOption v-else-if="!props.items?.length && !isSearching" el="div">
      <slot name="no-data"></slot>
    </OwnOption>

    <!--  Render as table  -->
    <OwnTableTableRenderer
      v-else-if="width > props.cardBreakpoint"
      :border="border"
      :items="props.items ?? props.value"
      :item-key="itemKey"
      :has-click="!!$listeners['click']"
      :reorderable="reorderable"
      :columns="props.columns"
      :variant="props.variant"
      @click="$emit('click', $event)"
      @reorder="onReorder"
    >
      <template v-for="column of columns" #[`heading.${column.key}`]>
        <slot :name="`heading.${column.key}`"></slot>
      </template>

      <template
        v-for="column of columns"
        #[`item.${column.key}`]="{ item, rowIdx, isFirst }"
      >
        <slot
          :name="`item.${column.key}`"
          :item="item"
          :row-idx="rowIdx"
          :is-first="isFirst"
        ></slot>
      </template>

      <template #item="{ item, columnKey, isFirst }">
        <slot
          name="item"
          :item="item"
          :column-key="columnKey"
          :is-first="isFirst"
        ></slot>
      </template>

      <template #no-data>
        <slot name="no-data"></slot>
      </template>

      <template #no-query-results>
        <slot name="no-query-results"></slot>
      </template>
    </OwnTableTableRenderer>

    <!--  Render as card set  -->
    <OwnTableCardRenderer
      v-else
      :has-click="!!$listeners['click']"
      :reorderable="reorderable"
      :items="props.items ?? props.value"
      :item-key="itemKey"
      @click="$emit('click', $event)"
    >
      <template #card="{ item, rowIdx }">
        <slot name="card" :item="item" :row-idx="rowIdx" />
      </template>
    </OwnTableCardRenderer>
  </Responsive>
</template>

<script lang="ts" setup generic="T extends Record<string, unknown>">
import { useVModel } from '@vueuse/core'
import { computed, WritableComputedRef } from 'vue'

import { reorder, ReorderEvent } from '@/utils/helpers/reorder'

import { OwnOption } from '../../OwnOption'
import Responsive from '../../Responsive.vue'
import { OwnTableColumnConfig, OwnTableVariants } from '../types'

import OwnTableCardRenderer from './OwnTableCardRenderer.vue'
import OwnTableTableRenderer from './OwnTableTableRenderer.vue'

const props = withDefaults(
  defineProps<{
    border?: boolean
    cardBreakpoint?: number
    columns: OwnTableColumnConfig<T>[]
    items?: T[]
    itemKey: string
    query?: string
    reorderable?: boolean
    value?: T[]
    variant?: OwnTableVariants
  }>(),
  {
    border: true,
    cardBreakpoint: 600,
    items: undefined,
    query: undefined,
    reorderable: false,
    value: () => [],
    variant: 'default',
  }
)

const modelVal = useVModel(props) as WritableComputedRef<T[]>

const isSearching = computed(() => {
  return props?.query !== ''
})

const onReorder = ({ oldIndex, newIndex }: ReorderEvent) => {
  modelVal.value = reorder(modelVal.value, oldIndex, newIndex)
}
</script>
