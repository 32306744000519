<template>
  <OwnStack ref="reorderableList" spacing="3">
    <template v-for="(item, index) of props.items">
      <OwnOption :key="item[itemKey]" @click="emit('click', item)">
        <OwnStack align="start" spacing="4" row>
          <DragHandle v-if="props.reorderable" />
          <slot name="card" :item="item" :row-idx="index"></slot>
        </OwnStack>
      </OwnOption>
    </template>
  </OwnStack>
</template>

<script lang="ts" setup generic="T extends Record<string, unknown>">
import Sortable, { SortableEvent } from 'sortablejs'
import { ComponentPublicInstance, onMounted, ref } from 'vue'

import { ReorderEvent } from '@/utils/helpers/reorder'

import { OwnOption } from '../../OwnOption'
import { OwnStack } from '../../OwnStack'

import DragHandle from '../../reorderable/DragHandle.vue'

const props = defineProps<{
  hasClick: boolean
  items: T[]
  itemKey: string
  reorderable: boolean
}>()

const emit = defineEmits<{
  (event: 'click', value: T): void
  (event: 'reorder', value: ReorderEvent): void
}>()

const reorderableList = ref<ComponentPublicInstance | null>(null)

onMounted(() => {
  if (props.reorderable && reorderableList.value) {
    // Lint issue - there is no-mixed-html here
    // eslint-disable-next-line xss/no-mixed-html
    Sortable.create(reorderableList.value.$el as HTMLElement, {
      animation: 150,
      direction: 'vertical',
      handle: '.drag-handle',
      onEnd: ({ oldIndex, newIndex }: SortableEvent) => {
        if (oldIndex === newIndex) return
        emit('reorder', { newIndex, oldIndex } as ReorderEvent)
      },
    })
  }
})
</script>
