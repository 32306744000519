<template>
  <Responsive v-slot="{ width }">
    <OwnCard class="own-callout" control>
      <OwnStack
        :spacing="width > BREAKPOINT ? 4 : 6"
        justify="between"
        :align="width > BREAKPOINT ? 'center' : 'start'"
        :row="width > BREAKPOINT"
      >
        <OwnStack
          :spacing="width > BREAKPOINT ? 4 : 6"
          :align="width > BREAKPOINT ? 'center' : 'start'"
          :row="width > BREAKPOINT"
        >
          <slot name="icon">
            <Component :is="icon" :class="iconColor" size="24" />
          </slot>

          <OwnStack class="flex-1" spacing="1">
            <OwnType
              v-if="title"
              :text="title"
              variant="subtitle"
              color="primary"
            />
            <OwnType :text="body" variant="paragraph-small" />
          </OwnStack>
        </OwnStack>

        <div class="flex-shrink-0">
          <slot name="right-action"></slot>
        </div>
      </OwnStack>
    </OwnCard>
  </Responsive>
</template>

<script lang="ts" setup>
import {
  PhCheckCircle,
  PhInfo,
  PhWarning,
  PhWarningOctagon,
} from 'phosphor-vue/dist/phosphor-vue.esm'
import { Component, computed } from 'vue'

import { OwnCard } from '../OwnCard'
import { OwnStack } from '../OwnStack'
import { OwnType } from '../OwnType'
import Responsive from '../Responsive.vue'

const BREAKPOINT = 600

const props = withDefaults(
  defineProps<{
    body: string
    title?: string
    type?: 'warning' | 'danger' | 'success' | 'info'
  }>(),
  {
    title: undefined,
    type: 'info',
  }
)

const icon = computed<Component>(() => {
  switch (props.type) {
    case 'danger':
      return PhWarningOctagon
    case 'warning':
      return PhWarning
    case 'success':
      return PhCheckCircle
    default:
      return PhInfo
  }
})

const iconColor = computed(() => {
  switch (props.type) {
    case 'danger':
      return 'status-danger'
    case 'warning':
      return 'status-warning'
    case 'success':
      return 'status-success'
    default:
      return 'misc-brand'
  }
})
</script>

<style lang="scss">
.own-callout {
  padding: 16px;
  border-radius: 8px;
}
</style>
