<template>
  <!--
    eslint-disable vue/no-v-html

    The only place this is coming from is @owner/icons
    so an XSS attack is unlikely
  -->
  <div
    v-if="isCustomIcon"
    class="own-icon"
    :class="[`own-icon--${size}`, color]"
    :style="style"
    v-on="$listeners"
    v-html="iconContent"
  ></div>
  <component
    :is="icon"
    v-else
    class="own-icon"
    :class="[`own-icon--${size}`, color]"
    :size="phSize"
    :weight="phSize > 16 ? 'bold' : 'regular'"
    v-on="$listeners"
  />
</template>
<script>
import { hermes as hermesIcons } from '@owner/icons'

import { colors } from '../Common/color.validator'
import { sizeValidator } from '../Common/size.validator'

/**
 * @deprecated Use Phosphor icon directly
 */
export default {
  name: 'OwnIcon',
  props: {
    /** Color of icon */
    color: {
      type: String,
      default: undefined,
      validator: colors,
    },

    /** Name of icon in icon library */
    icon: {
      type: [String, Function],
      required: true,
    },

    /** Rotation of icon */
    rotate: {
      type: Number,
      default: 0,
    },

    /** Size of icon */
    size: {
      type: String,
      default: 'xs',
      validator: sizeValidator,
    },
  },
  computed: {
    iconContent() {
      const { icon } = this
      if (hermesIcons[icon]) {
        return hermesIcons[icon].data
      }
      return undefined
    },
    isCustomIcon() {
      const { icon } = this

      return typeof icon === 'string'
    },
    phSize() {
      const { size } = this

      switch (size) {
        case 'xxs':
          return 8
        case 'xs':
          return 12
        case 'md':
          return 16
        case 'lg':
          return 22
        case 'xl':
          return 28
        default:
          return 16
      }
    },
    style() {
      const { rotate } = this
      if (rotate) {
        return {
          transform: `rotate(${rotate}deg)`,
        }
      }
      return undefined
    },
  },
}
</script>
<style lang="scss">
@import '@/styles/abstracts/variables/colors';
@import './scss/size';
@import './scss/icon-colors';

.own-icon {
  $xxs-icon-size: 12px;
  $xs-icon-size: 16px;
  $sm-icon-size: 22px;
  $md-icon-size: 28px;
  $lg-icon-size: 32px;
  $xl-icon-size: 42px;

  flex-shrink: 0;

  &--xxs {
    @include set-square-size($xxs-icon-size);
  }

  &--xs {
    @include set-square-size($xs-icon-size);
  }

  &--sm {
    @include set-square-size($sm-icon-size);
  }

  &--md {
    @include set-square-size($md-icon-size);
  }

  &--lg {
    @include set-square-size($lg-icon-size);
  }

  &--xl {
    @include set-square-size($xl-icon-size);
  }

  @each $color-name, $color-map in $palates {
    @each $color-descriptor, $color-value in $color-map {
      &--#{$color-name}-#{$color-descriptor} ::v-deep .own-icon__element {
        @include build-color-option($color-value);
      }
    }
  }

  &--white ::v-deep .own-icon__element {
    &--fill {
      fill: $white;
    }

    &--stroke {
      stroke: $white;
    }

    &--stroke-fill {
      fill: $white;
      stroke: $white;
    }
  }
}
</style>
