<template>
  <form class="own-form" novalidate @submit.prevent="onFormSubmit">
    <!--
      @slot default
     -->
    <slot :processing="isProcessing"></slot>
  </form>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue'

const props = withDefaults(
  defineProps<{
    processing?: boolean
    submitHandler?: () => Promise<void>
  }>(),
  {
    processing: false,
    submitHandler: undefined,
  }
)

const isRunningSubmitHandler = ref(false)

const isProcessing = computed(() => {
  return props.processing || isRunningSubmitHandler.value
})

const onFormSubmit = async () => {
  if (!isProcessing.value && props.submitHandler) {
    try {
      isRunningSubmitHandler.value = true
      await props.submitHandler()
    } catch (err) {
      // Rethrow to consumer
      throw err
    } finally {
      isRunningSubmitHandler.value = false
    }
  }
}
</script>
