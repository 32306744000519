<template>
  <div class="own-grid">
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

interface OwnGridProps {
  columns?: number
  spacing?: number
}

const props = withDefaults(defineProps<OwnGridProps>(), {
  columns: 2,
  spacing: 3,
})

// v-bind styles can't reference children
const styleColumns = computed(() => props.columns)

const pxGap = computed(() => `${props.spacing * 4}px`)
</script>

<style lang="scss">
.own-grid {
  display: grid;
  grid-template-columns: repeat(v-bind(styleColumns), 1fr);

  gap: v-bind(pxGap);
}
</style>
