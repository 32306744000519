<template>
  <OwnStack class="own-radio-button-group">
    <OwnStack
      v-for="option of options"
      :key="option.value"
      class="own-radio-button-group__row"
      :class="[listItem && 'own-radio-button-group__row--list-item']"
      row
    >
      <OwnStack spacing="2" row>
        <input
          :id="option.value"
          v-model="selected"
          type="radio"
          :value="option.value"
        />
        <label
          :for="option.value"
          class="text-paragraph"
          :class="[
            selected === option.value
              ? 'text-color-primary'
              : 'text-color-secondary',
          ]"
          v-text="option.label"
        ></label>
      </OwnStack>
      <p
        v-if="option.rightText"
        class="own-radio-button-group__right-text text-paragraph"
        :class="[
          selected === option.value
            ? 'text-color-primary'
            : 'text-color-secondary',
        ]"
        v-text="option.rightText"
      ></p>
    </OwnStack>
  </OwnStack>
</template>
<script>
import { OwnStack } from '../OwnStack'

/**
 * @deprecated Use OwnRadioGroup
 */
export default {
  name: 'OwnRadioButtonGroup',
  components: {
    OwnStack,
  },
  props: {
    /**
     * Show as list item
     */
    listItem: {
      type: Boolean,
      default: false,
    },

    /**
     * Select Options
     */
    options: {
      type: Array,
      default: () => [],
    },

    /**
     * @model
     */
    value: { type: String, default: undefined },
  },
  computed: {
    selected: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
<style lang="scss">
.own-radio-button-group {
  &__row {
    &--list-item {
      padding: 12px 16px;
      border-bottom: 1px solid $slate-100;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &__right-text {
    flex-shrink: 0;
  }
}
</style>
