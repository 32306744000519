<template>
  <div
    class="own-status-badge"
    :class="[
      `bg-${color}-${colorShade}`,
      clamp && 'own-status-badge--clamp',
      small && 'own-status-badge--small',
    ]"
    v-on="$listeners"
  >
    <p
      class="text-button text--truncate"
      :class="`${color}-400`"
      v-text="label"
    ></p>
    <slot name="right-action"></slot>
  </div>
</template>
<script>
/**
 * @deprecated Use OwnStatus
 */
export default {
  name: 'OwnStatusBadge',
  props: {
    /**
     * Clamp to content width
     */
    clamp: {
      type: Boolean,
      default: false,
    },

    /**
     * Color Family
     *
     * Options: slate, mallard, target, shine, bluetiful
     */
    color: {
      type: String,
      default: 'slate',
      validator: (v) =>
        ['slate', 'mallard', 'target', 'shine', 'bluetiful'].includes(v),
    },

    /**
     * Text inside Badge
     */
    label: { type: String, default: undefined },

    /**
     * Clamp to content width
     */
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    colorShade() {
      const { color } = this

      return color === 'bluetiful' || color === 'slate' ? '200' : '100'
    },
  },
}
</script>
<style lang="scss">
.own-status-badge {
  padding: 4px 8px;
  border-radius: 16px;

  display: flex;
  flex-direction: row;
  align-items: center;

  &--small {
    padding: 2px 4px;
  }

  &--clamp {
    align-self: flex-start;
  }
}
</style>
