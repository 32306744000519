<script>
import { isDefined } from '@vueuse/core'

import { OwnStack } from '../OwnStack'
import { OwnType } from '../OwnType'

import OwnInputHelperRow from './components/OwnInputHelperRow'
import OwnInputLabel from './components/OwnInputLabel'

export default {
  name: 'OwnInputContainer',
  components: {
    OwnInputHelperRow,
    OwnInputLabel,
    OwnStack,
    OwnType,
  },
  props: {
    /**
     * Description of input field
     */
    description: { type: String, default: undefined },

    /**
     * List of errors
     */
    errors: { type: Array, default: () => [] },

    /**
     * Link to define even more context
     */
    helperLink: { type: [String, Object], default: undefined },

    /**
     * Label to show for helper link
     */
    helperLinkText: { type: String, default: undefined },

    /**
     * Helper text to show
     */
    helperText: { type: String, default: undefined },

    /**
     * Label
     */
    label: { type: String, default: undefined },

    /**
     * Max chars allowed
     */
    maxChars: { type: Number, default: undefined },

    /**
     * Shows optional tag
     */
    optional: { type: Boolean, default: false },

    /**
     * Force shrinking when possible
     */
    shrink: { type: Boolean, default: false },

    /**
     * Only show for screen readers
     */
    srOnly: { type: Boolean, default: false },

    /**
     * Current value of the input
     */
    value: { type: String, default: undefined },
  },
  computed: {
    accessibilityId() {
      const { label } = this

      if (!label) {
        return undefined
      }

      const splitLabel = label.split(' ')

      return splitLabel
        .map((labelPart) => labelPart.toLowerCase())
        .filter((labelPart) => /^[a-z0-9_-]*$/.test(labelPart))
        .join('-')
    },
    hasError() {
      const { errors } = this
      return errors.length > 0
    },
  },
  methods: {
    buildColumnLayout(createEl) {
      const { shrink, srOnly } = this

      return createEl(
        OwnStack,
        {
          class: ['own-input-container'],
          props: {
            shrink,
            spacing: srOnly ? '0' : '2',
          },
        },
        [
          this.buildLabelElement(createEl),
          this.extendInputWithErrorProp(),
          this.buildHelperRow(createEl),
        ]
      )
    },
    buildHelperRow(createEl) {
      const {
        accessibilityId,
        helperLink,
        helperLinkText,
        helperText,
        hasError,
        errors,
        row,
      } = this

      return createEl(OwnInputHelperRow, {
        props: {
          accessibilityId,
          error: hasError,
          helperLink,
          helperLinkText,
          helperText: hasError ? errors[0] : helperText,
          row,
        },
      })
    },
    buildLabelElement(createEl) {
      const {
        accessibilityId,
        label,
        description,
        maxChars,
        srOnly,
        optional,
        value,
      } = this

      if (label || description) {
        const children = []

        const inputLabelElement = createEl(
          OwnInputLabel,
          {
            class: [
              'own-input-container__label',
              srOnly && 'own-input-container__sr-only',
            ],
            props: {
              accessibilityId,
              description,
              label,
              optional,
            },
          },
          []
        )

        if (this.$slots.prepend) {
          children.push(
            createEl(
              OwnStack,
              {
                props: {
                  align: 'start',
                  justify: 'start',
                  row: true,
                  spacing: '2',
                },
              },
              [this.$slots.prepend[0], inputLabelElement]
            )
          )
        } else {
          children.push(inputLabelElement)
        }

        const charCountEl =
          isDefined(maxChars) && isDefined(value)
            ? createEl(OwnType, {
                props: {
                  text: `${value?.length ?? 0}/${maxChars}`,
                  variant: 'paragraph-small',
                },
              })
            : undefined

        if (charCountEl) children.push(charCountEl)

        return createEl(
          OwnStack,
          {
            props: {
              align: 'center',
              justify: 'between',
              row: true,
              spacing: '2',
            },
          },
          children
        )
      }

      return undefined
    },
    extendInputWithErrorProp() {
      const { accessibilityId, hasError } = this

      const defaultSlot = this.$slots.default

      const renderedInputElement = defaultSlot[0]

      if (renderedInputElement.componentOptions) {
        renderedInputElement.componentOptions.propsData = {
          ...renderedInputElement.componentOptions.propsData,
          accessibilityId,
          error: hasError,
        }
      }

      return renderedInputElement
    },
  },
  render(createEl) {
    const defaultSlot = this.$slots.default

    if (defaultSlot?.length !== 1) {
      return createEl('div')
    }

    return this.buildColumnLayout(createEl)
  },
}
</script>
<style lang="scss">
.own-input-container {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 20px;
  min-width: 20px;

  &__column {
    flex-grow: 1;
  }

  &__input-column {
    width: 50%;
  }

  &__sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
}
</style>
