<template>
  <component :is="el" ref="obs-target" v-on="$listeners">
    <slot :height="height" :width="width"></slot>
  </component>
</template>

<script>
import throttle from 'lodash/throttle'

export default {
  name: 'Responsive',
  props: {
    /**
     * Tag to use
     */
    el: { type: String, default: 'div' },
  },
  data() {
    return {
      height: 0,
      init: false,
      obs: null,
      width: 0,
    }
  },
  mounted() {
    this.$nextTick(() => {
      const handleResize = throttle((entries) => {
        const cr = entries[0].contentRect

        const roundedHeight =
          Math.round((cr.height + Number.EPSILON) * 100) / 100
        const roundedWidth = Math.round((cr.width + Number.EPSILON) * 100) / 100

        if (
          Math.abs(roundedHeight - this.height) > 0.05 ||
          Math.abs(roundedWidth - this.width) > 0.05
        ) {
          this.height = roundedHeight
          this.width = roundedWidth

          this.$emit('size-change', {
            height: roundedHeight,
            width: roundedWidth,
          })
        }
      }, 200)

      this.obs = new ResizeObserver(handleResize)

      const targetEl = this.$refs['obs-target']

      if (targetEl instanceof Element) {
        this.obs.observe(targetEl)
      }
    })

    this.init = true
  },
  destroyed() {
    if (this.obs) {
      this.obs.disconnect()
    }
  },
}
</script>
