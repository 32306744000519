<template>
  <div class="flex-col">
    <label
      v-for="item of items"
      :key="item.id"
      class="own-checkbox-group__row flex-row justify-between align-center"
      :class="[
        disabledItems.includes(item.id) && 'own-checkbox-group__row--disabled',
      ]"
      :for="item.id"
    >
      <span class="flex-row gap-2 align-center">
        <slot name="prefix" :item="item" />

        <OwnType
          :text="item.label"
          el="span"
          variant="subtitle"
          :color="disabledItems.includes(item.id) ? 'secondary' : 'primary'"
        />
      </span>

      <input
        :id="item.id"
        v-model="selectedValues"
        :disabled="disabledItems.includes(item.id)"
        :value="item.id"
        type="checkbox"
        @input="onCheckboxInput(item.id)"
      />
    </label>
  </div>
</template>

<script lang="ts" setup generic="T extends Option<string> & { id: string }">
import { useVModel } from '@vueuse/core'

import { OwnType } from '../OwnType'
import { Option } from '../types'

const props = withDefaults(
  defineProps<{
    disabledItems?: string[]

    /**
     * Items to render
     */
    items: T[]

    /**
     * Selected items
     * @model
     */
    value: string[]
  }>(),
  {
    disabledItems: () => [],
  }
)
const emit = defineEmits<{
  (event: 'select', value: string): void
  (event: 'deselect', value: string): void
  (event: 'input', value: string[]): void
}>()

const selectedValues = useVModel(props, 'value', emit, { eventName: 'input' })

const onCheckboxInput = (interactionId: string) => {
  if (selectedValues.value.includes(interactionId)) {
    emit('deselect', interactionId)
  } else {
    emit('select', interactionId)
  }
}
</script>

<style lang="scss">
.own-checkbox-group {
  &__row {
    padding: 12px 0;
    border-bottom: 1px dashed $background-divider;
    user-select: none;
    cursor: pointer;

    &:first-child {
      padding: 0 0 12px 0;
    }

    &:last-child {
      padding: 12px 0 0 0;
      border-bottom: none;
    }

    &--disabled {
      cursor: not-allowed;
    }
  }
}
</style>
