<template>
  <OwnCard
    :el="el"
    class="own-option w-full"
    :background-color="active ? 'background-selected' : undefined"
    control
    v-bind="el === 'button' && { type: 'button' }"
    v-on="$listeners"
  >
    <slot></slot>
  </OwnCard>
</template>

<script>
import { OwnCard } from '../OwnCard'

export default {
  name: 'OwnOption',
  components: {
    OwnCard,
  },
  props: {
    /**
     * Option is active
     */
    active: { type: Boolean, default: false },

    /**
     * Element
     */
    el: { type: String, default: 'button' },
  },
}
</script>

<style lang="scss">
.own-option {
  padding: 12px 16px;
}
</style>
