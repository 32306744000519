<template>
  <OwnStack class="own-card-heading" spacing="2">
    <OwnStack
      class="own-card-heading__h-main-row h-full flex-1"
      justify="between"
      align="center"
      row
    >
      <OwnStack
        class="own-card-heading__h-main-row h-full flex-1"
        align="center"
        row
      >
        <span
          v-if="title"
          class="own-card-heading__title text-subtitle-1"
          v-text="title"
        ></span>
      </OwnStack>

      <OwnStack
        v-if="$slots['center-action']"
        class="own-card-heading__h-main-row h-full flex-1"
        justify="center"
        align="end"
        row
      >
        <slot class="flex-1" name="center-action"></slot>
      </OwnStack>

      <OwnStack
        v-if="$slots['right-action'] || to || $slots['center-action']"
        class="own-card-heading__h-main-row own-card-heading__rhs h-full flex-1"
        spacing="2"
        justify="end"
        align="center"
        row
      >
        <slot name="right-action"></slot>
        <OwnButton
          v-if="to"
          :icon-left="linkIcon"
          :text="linkText"
          small
          @click="navigateTo"
        />
      </OwnStack>
    </OwnStack>
    <OwnStack
      v-if="$slots['accent-row']"
      class="own-card-heading__accent-row"
      justify="center"
      row
    >
      <slot name="accent-row"></slot>
    </OwnStack>
  </OwnStack>
</template>

<script>
import { OwnButton } from '../../OwnButton'
import { OwnStack } from '../../OwnStack'

export default {
  name: 'OwnCardHeading',
  components: {
    OwnButton,
    OwnStack,
  },
  props: {
    /**
     * Link Icon
     */
    linkIcon: { type: [String, Function], default: undefined },

    /**
     * Link Text
     */
    linkText: { type: String, default: undefined },

    /**
     * Title
     */
    title: { type: String, default: undefined },

    /**
     * Link To Page
     */
    to: { type: Object, default: undefined },
  },
  methods: {
    navigateTo() {
      const { to } = this
      if (to) {
        this.$router.push(to)
      }
    },
  },
}
</script>

<style lang="scss">
.own-card-heading {
  background: $white;
  border-radius: 8px 8px 0 0;
  min-height: 56px;

  border-bottom: 1px solid $background-divider;

  &__h-main-row {
    height: 56px;
  }

  &__title {
    width: 100%;
    flex-shrink: 0;
    padding: 8px 16px;
    overflow-wrap: break-word;
  }

  &__accent-row {
    padding: 0 8px 8px 8px;
  }

  &__rhs {
    padding: 8px 16px;
  }
}
</style>
