<template>
  <OwnSkeletonLoader height="24">
    <OwnStack
      v-if="
        !loading &&
        (getItemValue(item, heading) !== null ||
          getItemValue(item, heading) !== undefined)
      "
      class="own-data-cell__container"
      align="center"
      row
    >
      <p
        :class="
          heading.class
            ? `${heading.class} text--truncate`
            : 'text-paragraph slate-500 text--truncate'
        "
        v-text="formatValue(getItemValue(item, heading), heading)"
      ></p>
    </OwnStack>
  </OwnSkeletonLoader>
</template>
<script>
import { formatMinorAmount } from '@owner/currency'
import { parseISO, format } from 'date-fns'
import get from 'lodash/get'

import { formatPhoneNumber } from '@/utils/format/phone'

import { OwnSkeletonLoader } from '../OwnSkeletonLoader'
import { OwnStack } from '../OwnStack'

const formatDate = (date) => {
  const parsedDate = parseISO(date)
  return format(parsedDate, 'MMM dd, yyyy')
}

export default {
  name: 'OwnDataCell',
  components: {
    OwnSkeletonLoader,
    OwnStack,
  },
  props: {
    /**
     * Specific heading for this cell
     */
    heading: { type: Object, required: true },

    /**
     * Item in cell
     */
    item: { type: Object, required: true },

    /**
     * Loading
     */
    loading: { type: Boolean, default: false },
  },
  methods: {
    formatValue(value, heading) {
      if (value === null || value === undefined) {
        return '—'
      }

      switch (heading.format) {
        case 'currency':
          return formatMinorAmount(value)

        case 'numeric':
          return value.toLocaleString()

        case 'phone':
          return formatPhoneNumber(value.slice(2))

        case 'date':
          return formatDate(value)

        default:
          return value
      }
    },
    getItemValue(item, heading) {
      try {
        const { itemKey, value } = heading

        if (itemKey) {
          return get(item, itemKey)
        }

        return item[value]
      } catch (error) {
        return undefined
      }
    },
  },
}
</script>
<style lang="scss">
.own-data-cell {
  &__container {
    height: 100%;
  }
}
</style>
