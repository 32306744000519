const VALID_COLORS = [
  'bluetiful-blur',
  'bluetiful-100',
  'bluetiful-200',
  'bluetiful-300',
  'bluetiful-400',
  'bluetiful-500',
  'bluetiful-600',
  'bluetiful-700',
  'slate-blur',
  'slate-100',
  'slate-200',
  'slate-300',
  'slate-400',
  'slate-500',
  'slate-600',
  'slate-700',
  'shine-100',
  'shine-400',
  'target-100',
  'target-400',
  'mallard-100',
  'mallard-400',
  'white',
] as const

type ValidColor = (typeof VALID_COLORS)[number]

export const colors = () => {
  return (v: string): v is ValidColor => VALID_COLORS.includes(v as ValidColor)
}
