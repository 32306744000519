<template>
  <div class="own-data-heading-cell" :style="{ justifyContent: justify }">
    <span
      v-if="showLabel"
      class="text-subtitle-2 text-color-secondary"
      v-text="heading.text"
    ></span>
  </div>
</template>
<script>
export default {
  name: 'OwnDataHeadingCell',
  props: {
    /**
     * Heading
     */
    heading: { type: Object, required: true },
  },
  computed: {
    justify() {
      const { heading } = this

      switch (heading.justify) {
        case 'left':
          return 'flex-start'

        case 'center':
          return 'center'

        case 'right':
          return 'flex-end'

        default:
          return 'flex-start'
      }
    },
    showLabel() {
      const { heading } = this

      return !heading.hideLabel
    },
  },
}
</script>
<style lang="scss">
@import '@/styles/utilities/padding';

.own-data-heading-cell {
  display: flex;
  flex-direction: row;
  padding: 12px 8px;
}
</style>
