<template>
  <button
    class="own-button"
    :class="[
      isDefault && 'own-button--default',
      danger && 'own-button--danger',
      disabled && 'own-button--disabled',
      clamp && 'own-button--clamp',
      primary && 'own-button--primary',
      tertiary && 'own-button--tertiary',
      small && 'own-button--small',
      active && 'own-button--active',
      !text && 'own-button--icon-only',
    ]"
    :disabled="disabled"
    :type="type"
    @mousedown="onMouseDown"
    @mouseup="onMouseUp"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    @click.stop="onClick"
    v-on="safeListeners"
  >
    <template v-if="processing">
      <PhCircleNotch class="own-button__icon-loading" size="21" />
    </template>

    <template v-else>
      <span v-if="$slots['icon-left'] || iconLeft" class="own-button__icon">
        <slot name="icon-left">
          <OwnIcon v-if="iconLeft" :icon="iconLeft" :size="iconSize" />
        </slot>
      </span>
      <span
        v-if="text"
        :class="[
          fontSize,
          textTruncate && 'text--truncate',
          hasIconLeft && 'own-button__text--icon-left',
          hasIconRight && 'own-button__text--icon-right',
        ]"
        v-text="text"
      ></span>
      <span v-if="$slots['icon-right'] || iconRight" class="own-button__icon">
        <slot class="own-button__icon" name="icon-right">
          <OwnIcon v-if="iconRight" :icon="iconRight" :size="iconSize" />
        </slot>
      </span>
    </template>
  </button>
</template>

<script>
import { PhCircleNotch } from 'phosphor-vue/dist/phosphor-vue.esm'

import { OwnIcon } from '../OwnIcon'

export default {
  name: 'OwnButton',
  components: {
    OwnIcon,
    PhCircleNotch,
  },
  props: {
    /**
     * Active
     */
    active: {
      type: Boolean,
      default: false,
    },

    /**
     * Clamps the width of the button to the size of the content.  Default Acts as full width.
     */
    clamp: { type: Boolean, default: false },

    /**
     * Determines whether to show the danger state
     */
    danger: { type: Boolean, default: false },

    /**
     * Disabled state
     */
    disabled: { type: Boolean, default: false },

    /**
     * Icon to show on left of button text
     */
    iconLeft: {
      type: [String, Function],
      default: undefined,
    },

    /**
     * Icon to show on left of button text
     */
    iconRight: {
      type: [String, Function],
      default: undefined,
    },

    /**
     * Override the icon size
     */
    iconSize: { type: String, default: 'xs' },

    /**
     * Use Large font
     */
    largeFont: { type: Boolean, default: false },

    /**
     * Determines whether to show the primary state
     */
    primary: { type: Boolean, default: false },

    /**
     * Sets button to indeterminate processing state
     */
    processing: { type: Boolean, default: false },

    /**
     * Show small version of button
     */
    small: { type: Boolean, default: false },

    /**
     * Tertiary button type
     */
    tertiary: { type: Boolean, default: false },

    /**
     * Text to render on button
     */
    text: { type: String, default: undefined },

    /**
     * Truncate text on overflow
     */
    textTruncate: { type: Boolean, default: false },

    /**
     * Button Type
     */
    type: {
      type: String,
      default: 'button',
      validator: (v) => ['button', 'submit', 'reset'].includes(v),
    },
  },
  data() {
    return {
      isHovering: false,
      isMouseDown: false,
    }
  },
  computed: {
    fontSize() {
      const { largeFont, small } = this

      if (largeFont) {
        return 'text-subtitle-1'
      } else {
        return small ? 'text-button' : 'text-button-large'
      }
    },
    hasIconLeft() {
      const { iconLeft, $slots } = this
      return !!iconLeft || !!$slots['icon-left']
    },
    hasIconRight() {
      const { iconRight, $slots } = this
      return !!iconRight || !!$slots['icon-right']
    },
    isDefault() {
      const { primary, danger } = this

      return !primary && !danger
    },
    safeListeners() {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { click, ...rest } = this.$listeners

      return rest
    },
  },
  methods: {
    onClick(event) {
      const { processing } = this

      if (!processing) {
        this.$emit('click', event)
      }
    },
    onMouseDown(event) {
      this.isMouseDown = true
      this.$emit('mousedown', event)
    },
    onMouseEnter(event) {
      this.isHovering = true
      this.$emit('mouseenter', event)
    },
    onMouseLeave(event) {
      this.isHovering = false
      this.$emit('mouseleave', event)
    },
    onMouseUp(event) {
      this.isMouseDown = false
      this.$emit('mouseup', event)
    },
  },
}
</script>

<style lang="scss" scoped src="./OwnButton.scss"></style>
