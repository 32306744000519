<template>
  <component
    :is="el"
    class="own-stack"
    :class="[
      row || rowReverse ? `space-x-${spacing}` : `space-y-${spacing}`,
      row && 'own-stack--row',
      !row && 'own-stack--column',
      rowReverse && 'own-stack--row-reverse',
      colReverse && 'own-stack--col-reverse',
      !shrink && 'own-stack--fill',
      `own-stack--wrap-${wrap}`,
    ]"
    :style="stackStyles"
    v-on="$listeners"
  >
    <!-- @slot The children of the stack -->
    <slot></slot>
  </component>
</template>
<script>
/**
 * @deprecated Use an appropriate element with class based flex helpers `<div class="flex-row gap-2 justify-between align-center">`
 */
export default {
  name: 'OwnStack',
  props: {
    /**
     * How to flex-align the inner components
     * @values start, end, center, stretch
     */
    align: {
      type: String,
      default: 'stretch',
      validator: (v) => ['start', 'end', 'center', 'stretch'].includes(v),
    },

    /**
     * Render Colum Reverse layout
     */
    colReverse: { type: Boolean, default: false },

    /**
     * Render as different base component
     */
    el: { type: String, default: 'div' },

    /**
     * How to justify-content the inner components
     * @values start, end, center, between, around, evenly
     */
    justify: {
      type: String,
      default: 'start',
      validator: (v) =>
        [
          'start',
          'end',
          'center',
          'between',
          'around',
          'evenly',
          'stretch',
        ].includes(v),
    },

    /** Sets the orientation of the stack to a row */
    row: { default: false, type: Boolean },

    /** Sets the orientation to a reversed row */
    rowReverse: { default: false, type: Boolean },

    /** Minimizes the height or width */
    shrink: { default: false, type: Boolean },

    /** How much space to place between children */
    spacing: { type: [String, Number], default: 0 },

    /**
     * How to flex-wrap the inner components
     * @values nowrap, wrap, reverse
     */
    wrap: {
      type: String,
      default: 'nowrap',
      validator: (v) => ['nowrap', 'wrap', 'reverse'].includes(v),
    },
  },
  computed: {
    alignItems() {
      return {
        center: 'center',
        end: 'flex-end',
        start: 'flex-start',
        stretch: 'stretch',
      }[this.align]
    },
    justifyContent() {
      return {
        around: 'space-around',
        between: 'space-between',
        center: 'center',
        end: 'flex-end',
        evenly: 'space-evenly',
        start: 'flex-start',
      }[this.justify]
    },
    stackStyles() {
      return {
        'align-items': this.alignItems,
        'justify-content': this.justifyContent,
      }
    },
  },
}
</script>

<style lang="scss">
.own-stack {
  display: flex;
  flex-direction: column;

  &--row {
    flex-direction: row;
  }

  &--row-reverse {
    flex-direction: row-reverse;
  }

  &--col-reverse {
    flex-direction: column-reverse;
  }

  &--fill#{&}--row {
    width: 100%;
  }

  &--wrap-wrap {
    flex-wrap: wrap;
  }

  &--wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}
</style>
