
{
  "actions": {
    "download": "Download",
    "replace": "Replace",
    "remove": "Remove",
    "load": "Upload a file (Max 4 MB)",
    "select": "Select file"
  },
  "context": {
    "editing": "Editing {label}"
  },
  "labels": {
    "preview": "File Preview"
  },
  "errors": {
    "too-many-files": "Upload a single file at a time.",
    "not-enough-files": "Must upload one file."
  }
}
