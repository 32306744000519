<template>
  <OwnStack
    class="own-dropdown-menu-item"
    :class="[props.active && 'own-dropdown-menu-item__active', colorClass]"
    align="center"
    spacing="2"
    el="button"
    row
    @click="$emit('click')"
  >
    <slot name="left">
      <Component
        :is="props.icon"
        v-if="props.icon"
        class="flex-shrink-0"
        size="24"
      />
    </slot>

    <OwnType
      class="flex-1 text--truncate"
      el="span"
      :text="props.label"
      variant="subtitle"
      color="inherit"
      :title="props.label"
    />

    <slot name="right" />
  </OwnStack>
</template>

<script lang="ts" setup>
import { Component, computed } from 'vue'

import { OwnStack } from '../../OwnStack'
import { OwnType } from '../../OwnType'

interface DropdownItemCore {
  active?: boolean
  color?: 'neutral' | 'danger'
  icon?: Component
  label: string
}

const props = withDefaults(defineProps<DropdownItemCore>(), {
  active: false,
  color: 'neutral',
  icon: undefined,
})

defineEmits<(event: 'click') => void>()

const colorClass = computed<string>(() => {
  if (props.color === 'danger') {
    return 'status-danger'
  }

  return props.active ? 'text-color-primary' : 'text-color-secondary'
})
</script>

<style lang="scss">
.own-dropdown-menu-item {
  padding: 16px;
  border-radius: 4px;

  transition: background-color 100ms ease-in;

  &:hover {
    background-color: $background-highlight;
  }

  &__active {
    background-color: $background-highlight;
  }
}
</style>
