<template>
  <OwnStack class="own-mode-switch" row shrink>
    <OwnStack
      v-for="option of props.options"
      :key="option.value"
      class="own-mode-switch__button"
      :class="[modelVal === option.value && 'own-mode-switch__button--active']"
      el="button"
      justify="center"
      align="center"
      spacing="2"
      row
      @click="onClick(option.value)"
    >
      <component
        :is="option.icon"
        v-if="option.icon"
        class="flex-shrink-0"
        size="24"
      />

      <OwnType
        :text="option.label"
        el="span"
        variant="button"
        color="inherit"
      />
    </OwnStack>
  </OwnStack>
</template>

<script lang="ts" setup>
import { useVModel } from '@vueuse/core'
import { WritableComputedRef } from 'vue'

import { OwnStack } from '../OwnStack'
import { OwnType } from '../OwnType'

import { SwitchOption } from './types'

const props = withDefaults(
  defineProps<{
    options: SwitchOption[]
    value?: string
  }>(),
  {
    value: undefined,
  }
)

const modelVal = useVModel(props) as WritableComputedRef<string>

const onClick = (newValue: string) => {
  modelVal.value = newValue
}
</script>

<style lang="scss">
.own-mode-switch {
  height: 50px;
  padding: 4px;
  border: 1px solid $background-divider;
  border-radius: 8px;

  @include control-shadow();

  &__button {
    border-radius: 4px;
    padding: 0 16px;

    background-color: $background-primary;
    color: $text-color-secondary;

    &--active {
      background-color: $background-secondary;
      color: $text-color-primary;
    }
  }
}
</style>
