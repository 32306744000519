<template>
  <OwnStack
    v-if="helperText || helperLink"
    class="own-input-helper-row"
    :class="[row && 'own-input-helper-row--row']"
    :justify="rowJustification"
    align="center"
    row
  >
    <p
      v-if="helperText"
      :id="accessibilityId ? `${accessibilityId}-message` : undefined"
      class="own-input-helper-row__helper-text text-small"
      :class="[error ? 'target-400' : 'slate-400']"
      v-text="helperText"
    ></p>
    <template v-if="helperLink && isHelperLinkInternal">
      <router-link
        class="bluetiful-400 own-input-helper-row__helper-link text-small"
        :to="helperLink"
        v-text="useHelperLinkAsText ? helperLink : helperLinkText"
      />
    </template>
    <template v-else-if="helperLink">
      <a
        class="bluetiful-400 own-input-helper-row__helper-link text-small"
        @click.prevent="openHelperLinkInNewTab"
        v-text="useHelperLinkAsText ? helperLink : helperLinkText"
      ></a>
    </template>
  </OwnStack>
</template>
<script>
import { OwnStack } from '../../OwnStack'

export default {
  name: 'OwnInputHelperRow',
  components: {
    OwnStack,
  },
  props: {
    /**
     * Accessibility ID
     */
    accessibilityId: { type: String, default: undefined },

    /**
     * Has Error
     */
    error: { type: Boolean, default: false },

    /**
     * Link to define even more context
     */
    helperLink: { type: [String, Object], default: undefined },

    /**
     * Label to show for helper link
     */
    helperLinkText: { type: String, default: undefined },

    /**
     * Helper text to show
     */
    helperText: { type: String, default: undefined },

    /**
     * Is rendered within a row layout
     */
    row: { type: Boolean, default: false },
  },
  computed: {
    isHelperLinkInternal() {
      const { helperLink } = this

      if (typeof helperLink === 'object') {
        return true
      }

      return false
    },
    rowJustification() {
      const { row, helperText } = this

      if (row) {
        return 'start'
      } else if (!helperText) {
        return 'end'
      } else {
        return 'between'
      }
    },
    useHelperLinkAsText() {
      const { helperLink, helperLinkText } = this

      if (helperLink && !helperLinkText) {
        return true
      }

      return false
    },
  },
  methods: {
    openHelperLinkInNewTab() {
      const { helperLink } = this

      window.open(helperLink, '_blank')
    },
  },
}
</script>
<style lang="scss">
.own-input-helper-row {
  margin-top: 8px;

  &--row {
    margin-top: 0;
  }

  &__helper-text {
    margin-right: 8px;
  }

  &__helper-link {
    text-decoration: none;
  }
}
</style>
