<template>
  <div class="own-touch-radio-group" :style="style">
    <OwnOption
      v-for="{ icon, label, value: optionValue } of options"
      :key="optionValue"
      :active="optionValue === selected"
      @click="selected = optionValue"
    >
      <OwnStack justify="between" align="center" spacing="4" row>
        <OwnStack justify="start" align="center" spacing="4" row>
          <component :is="icon" size="24" />

          <OwnType :text="label" variant="subtitle" color="primary" />
        </OwnStack>

        <PhCheckCircle
          class="own-touch-radio-group__icon"
          :class="[
            optionValue === selected && 'own-touch-radio-group__icon--active',
          ]"
          size="24"
        />
      </OwnStack>
    </OwnOption>
  </div>
</template>

<script>
import { PhCheckCircle } from 'phosphor-vue/dist/phosphor-vue.esm'

import { OwnOption } from '../OwnOption'
import { OwnStack } from '../OwnStack'
import { OwnType } from '../OwnType'

export default {
  name: 'OwnTouchRadioGroup',
  components: {
    OwnOption,
    OwnStack,
    OwnType,
    PhCheckCircle,
  },
  props: {
    /**
     * Number of columns to split the list of options into on larger screens (a single column will always be used on
     * smaller screens).
     */
    columns: { type: Number, default: 1 },

    /**
     * Select Options
     */
    options: {
      type: Array,
      default: () => [],
    },

    /**
     * @model
     */
    value: { type: String, default: undefined },
  },
  computed: {
    selected: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    style() {
      const { columns } = this

      return {
        '--own-touch-radio-group-columns': columns,
      }
    },
  },
}
</script>

<style lang="scss">
.own-touch-radio-group {
  display: grid;
  gap: 12px;

  @include respond-to('md-and-up') {
    grid-template-columns: repeat(
      var(--own-touch-radio-group-columns, 1),
      auto
    );
  }

  &__icon {
    flex-shrink: 0;
    color: transparent;

    &--active {
      color: $bluetiful-500;
    }
  }
}
</style>
