/**
 * Provides a map of active breakpoints
 * @mixin
 */
export default {
  computed: {
    /**
     * A Breakpoint helper object
     * @public
     * @returns {object} a Vuetify breakpoint helper object
     */
    $breakpoints() {
      // Currently just wrapping Vuetify's breakpoint service.
      return this.$vuetify.breakpoint
    },
  },
}
