<template>
  <OwnStack
    :el="el"
    class="own-status w-full"
    :class="[`own-status--${status}`, isTrigger && 'own-status--trigger']"
    :justify="isTrigger ? 'between' : 'start'"
    align="center"
    row
  >
    <span class="text-button-large" v-text="text"></span>

    <PhCaretDown v-if="isTrigger" size="16" />
    <component :is="icon" v-else-if="icon" size="20" />
  </OwnStack>
</template>

<script>
import { PhCaretDown } from 'phosphor-vue/dist/phosphor-vue.esm'

import { OwnStack } from '../OwnStack'

export default {
  name: 'OwnStatus',
  components: { OwnStack, PhCaretDown },
  props: {
    /** Use this to render a specific component */
    el: { type: String, default: 'div' },

    /** An optional icon for the badge */
    icon: { type: Function, default: undefined },

    /** Is a trigger for an action menu */
    isTrigger: {
      type: Boolean,
      default: false,
    },

    /** The status for the badge */
    status: {
      type: String,
      validate: (v) => ['success', 'error', 'warning', 'neutral'].includes(v),
      default: 'neutral',
    },

    /** The text for the badge */
    text: { type: String, required: true },
  },
}
</script>

<style lang="scss">
@mixin define-color($color) {
  background: linear-gradient(
      0deg,
      rgba($color, 0.2) 0%,
      rgba($color, 0.2) 100%
    ),
    #fff;
  color: $color;

  &:hover.own-status--trigger {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(255, 255, 255, 0.5) 100%
      ),
      linear-gradient(0deg, rgba($color, 0.2) 0%, rgba($color, 0.2) 100%), #fff;
    color: rgba($color, 0.5);
  }
}

.own-status {
  border-radius: 8px;
  padding: 8px 16px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  @include control-shadow();

  &--neutral {
    @include define-color($text-color-secondary);
  }

  &--error {
    @include define-color($status-danger);
  }

  &--success {
    @include define-color($status-success);
  }

  &--warning {
    @include define-color($status-warning);
  }
}
</style>
