<template>
  <OwnPopover
    class="own-action-menu"
    align-y="bottom"
    align-x="left"
    :content-width="contentWidth"
    :slide="['x']"
    content-offset="8"
  >
    <template #trigger="{ on: { click } }">
      <button
        class="own-action-menu__trigger w-full"
        :class="[circle && 'own-action-menu__trigger--circle']"
        :disabled="disabled"
        @click.stop="click"
      >
        <slot name="trigger">
          <PhDotsThree class="text-color-secondary" size="24" weight="bold" />
        </slot>
      </button>
    </template>
    <template #content="{ close }">
      <OwnCard
        v-if="actions.length > 0"
        class="own-action-menu__content"
        control
      >
        <div v-for="(actionGroup, idx) of actionGroups" :key="idx">
          <hr v-if="idx !== 0" class="own-action-menu__group-rule" />
          <OwnStack class="own-action-menu__action-group">
            <OwnMenuRow
              v-for="action of actionGroup"
              :key="action.label"
              :action-item="action"
              @click.stop="actionClicked(action, close)"
            />
          </OwnStack>
        </div>
      </OwnCard>
    </template>
  </OwnPopover>
</template>

<script>
import { PhDotsThree } from 'phosphor-vue/dist/phosphor-vue.esm'

import { OwnCard } from '../../OwnCard'
import { OwnPopover } from '../../OwnPopover'
import { OwnStack } from '../../OwnStack'

import OwnMenuRow from './OwnMenuRow'

export default {
  name: 'OwnActionMenu',
  components: {
    OwnCard,
    OwnMenuRow,
    OwnPopover,
    OwnStack,
    PhDotsThree,
  },
  props: {
    /** Actions To Render */
    actions: {
      type: Array,
      default: () => [],
    },

    /**
     * Show background circle
     */
    circle: { type: Boolean, default: false },

    /**
     * Content Width
     */
    contentWidth: {
      type: String,
      default: '160',
    },

    /** When `true`, the menu will be disabled */
    disabled: { type: Boolean, default: false },
  },
  computed: {
    actionGroups() {
      const { actions } = this

      if (!actions.length) {
        return [[]]
      }

      if (Array.isArray(actions[0])) {
        return actions
      } else {
        return [actions]
      }
    },
  },
  methods: {
    actionClicked({ value, preventClose }, close) {
      this.$emit('action', value)

      if (!preventClose) {
        close()
        this.$emit('close')
      }
    },
  },
}
</script>

<style lang="scss">
.own-action-menu {
  align-self: start;

  &__trigger {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &--circle {
      border-radius: 12px;
      background-color: $background-divider;
    }
  }

  &__content {
    padding: 4px;
    border-radius: 8px;
  }

  &__group-rule {
    height: 0;
    background-color: transparent;
    border: none;
    border-top: 1px dashed $background-divider;
  }
}
</style>
