<template>
  <input
    :id="accessibilityId"
    :class="[rounded && 'rounded']"
    :checked="modelVal"
    type="checkbox"
    :disabled="disabled"
    @click.stop="onClick"
  />
</template>
<script lang="ts" setup>
import { useVModel } from '@vueuse/core'
import { WritableComputedRef } from 'vue'

interface OwnCheckboxProps {
  accessibilityId?: string
  disabled?: boolean
  rounded?: boolean
  value?: boolean
}

const props = withDefaults(defineProps<OwnCheckboxProps>(), {
  accessibilityId: undefined,
  disabled: false,
  rounded: false,
  value: false,
})

const modelVal = useVModel(props) as WritableComputedRef<boolean>

const onClick = () => {
  modelVal.value = !modelVal.value
}
</script>
<style lang="scss">
@import '../Common/scss/focus';

input[type='checkbox'] {
  position: relative;
  width: 24px;
  height: 24px;
  color: #000000;
  border: 1px solid $background-divider;
  border-radius: 8px;
  appearance: none;
  outline: 0;
  cursor: pointer;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);

  flex-shrink: 0;

  & .rounded {
    border-radius: 50%;
  }

  &:disabled {
    background-color: $background-secondary;
    cursor: not-allowed;
  }

  &:not(#{&}:disabled) {
    @include control-shadow();
  }

  &::before {
    position: absolute;
    content: '';
    display: block;
    top: 3px;
    left: 8px;
    width: 6px;
    height: 12px;
    border-style: solid;
    border-color: $background-divider;
    border-width: 0 1.5px 1.5px 0;
    transform: rotate(37deg);
    opacity: 0;
  }

  &:hover::before {
    border-color: $background-divider;
  }

  &:checked {
    color: $white;
    border-color: $bluetiful-500;
    background: $bluetiful-500;

    &::before {
      opacity: 1;
    }

    ~ label::before {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }

  &:focus {
    @include custom-focus-ring();
  }
}
</style>
