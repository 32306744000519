import { type DirectiveBinding, type ObjectDirective } from 'vue'

interface ElementWithClickOutside extends HTMLElement {
  _clickOutside: (event: Event) => void
}

type Method =
  | (() => void)
  | { include: () => HTMLElement[]; handler: (event: Event) => void }

const isTrustedEvent = (event: Event): boolean => {
  return 'isTrusted' in event && event.isTrusted
}

const getElements = (method: DirectiveBinding<Method>): HTMLElement[] => {
  if (typeof method.value === 'object' && method.value.include) {
    return method.value.include()
  } else {
    return []
  }
}

const isElementTarget = (elements: HTMLElement[], event: Event): boolean => {
  return !elements.some((element) => element.contains(event.target as Node))
}

const waitUntilNextTick = (cb: () => void): void => {
  setTimeout(() => {
    cb()
  }, 0)
}

const determineClickOutside = (
  event: Event,
  el: HTMLElement,
  method: DirectiveBinding<Method>
): void => {
  const handler =
    typeof method.value === 'function' ? method.value : method.value.handler

  if (!event || !isTrustedEvent(event)) {
    return
  }

  const elements = getElements(method)

  elements.push(el)

  if (isElementTarget(elements, event) && handler) {
    waitUntilNextTick(() => {
      handler(event)
    })
  }
}

export const clickOutside: ObjectDirective<ElementWithClickOutside, Method> = {
  /**
   * Calls method when clicked outside of element
   * @param el the DOM element
   * @param method a Vue component method to call when clicked outside of element
   * @public
   */
  bind(el, method) {
    el._clickOutside = (event: Event) =>
      determineClickOutside(event, el, method)
    document.body.addEventListener('click', el._clickOutside, true)
  },
  /**
   * Unbinds the click listener created when the directive was initialized
   * @param el The DOM element
   */
  unbind(el) {
    document.body.removeEventListener('click', el._clickOutside, true)
  },
}
