<template>
  <div class="flex-col">
    <label
      v-for="option of options"
      :key="option.value"
      class="own-radio-group__row flex-row align-center justify-between"
      :for="option.value"
    >
      <OwnType
        :text="option.label"
        el="span"
        variant="subtitle"
        color="primary"
      />

      <input
        :id="option.value"
        v-model="selectedValues"
        :value="option.value"
        type="radio"
      />
    </label>
  </div>
</template>

<script lang="ts" setup>
import { useVModel } from '@vueuse/core'
import { WritableComputedRef } from 'vue'

import { OwnType } from '../OwnType'

import { Option } from '../types'

interface OwnRadioGroupProps {
  options?: Option<string>[]
  value?: string
}

const props = withDefaults(defineProps<OwnRadioGroupProps>(), {
  options: () => [],
  value: undefined,
})

const selectedValues = useVModel(props) as WritableComputedRef<
  string | undefined
>
</script>

<style lang="scss">
input[type='radio'] {
  cursor: pointer;
  height: 24px;
  width: 24px;
  accent-color: $misc-brand;
}

.own-radio-group {
  &__row {
    padding: 12px 0;
    border-bottom: 1px dashed $background-divider;
    user-select: none;
    cursor: pointer;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}
</style>
