<template>
  <OwnCard
    class="own-card-list"
    :class="[control && 'own-card-list--control']"
    :hide-border="!control"
    :control="control"
  >
    <template v-if="items.length && reorderable">
      <Reorderable
        v-slot="{ item, index }"
        v-model="items"
        item-key="_key"
        row-class="own-card-list__row"
        @reorder="onReorder"
      >
        <OwnStack
          align="center"
          spacing="3"
          :el="rowElement"
          row
          v-on="normalizedListeners(item, index)"
        >
          <DragHandle v-if="reorderable && items.length > 1" />
          <slot name="item" :item="item" :index="index"></slot>
        </OwnStack>
      </Reorderable>
    </template>

    <template v-else-if="items.length">
      <component
        :is="rowElement"
        v-for="(item, index) in items"
        :key="item._key"
        class="own-card-list__row"
        v-on="normalizedListeners(item, index)"
      >
        <slot name="item" :item="item" :index="index"></slot>
      </component>
    </template>

    <template v-else>
      <slot name="empty"></slot>
    </template>
  </OwnCard>
</template>

<script>
import { reorder } from '@/utils/helpers/reorder'

import { OwnCard } from '../OwnCard'
import { OwnStack } from '../OwnStack'
import DragHandle from '../reorderable/DragHandle.vue'
import Reorderable from '../reorderable/Reorderable.vue'

export default {
  name: 'OwnCardList',
  components: {
    DragHandle,
    OwnCard,
    OwnStack,
    Reorderable,
  },
  props: {
    control: { type: Boolean, default: false },

    /**
     * Block key to be used for for-key.  When a string is provided,
     * the OwnCardList will search for item keys of that value.
     * An explicit null value can be used here to force updates for every
     * change events.  This is a heavy operation but allows arrays with no
     * unique values to be used.
     * @default id
     */
    itemKey: { type: [String, null], default: 'id' },

    reorderable: { type: Boolean, default: false },

    /**
     * List of items
     * @model
     */
    value: { type: Array, default: () => [] },
  },
  computed: {
    items: {
      get() {
        const { itemKey } = this
        if (itemKey) {
          return this.value.map((item) => ({
            ...item,
            _key: item[itemKey],
          }))
        }

        return this.value.map((item) => ({
          ...item,
          _key: `${Date.now().toString()}-${Math.random()}`,
        }))
      },
      set(newVal) {
        this.$emit(
          'input',
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          newVal.map(({ _key, ...value }) => value)
        )
      },
    },
    rowElement() {
      if (this.$listeners.click) {
        return 'button'
      } else {
        return 'div'
      }
    },
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    normalizedListeners({ _key, ...item }, index) {
      if (this.$listeners.click) {
        return {
          click: () => this.$emit('click', { index, item }),
        }
      } else {
        return undefined
      }
    },
    onReorder({ newIndex, oldIndex }) {
      this.items = reorder(this.items, oldIndex, newIndex)
    },
  },
}
</script>

<style lang="scss">
.own-card-list {
  &--control {
    padding: 16px;
  }

  &__row {
    background-color: $background-primary;
    padding: 12px 0;
    border-bottom: 1px dashed $background-divider;

    &:first-child {
      padding: 0 0 12px 0;
    }

    &:last-child {
      padding: 12px 0 0 0;
      border-bottom: none;
    }

    &:only-child {
      padding: 0;
    }
  }
}
</style>
