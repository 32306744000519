<template>
  <textarea
    :id="accessibilityId"
    v-model="modelVal"
    class="own-textarea text-paragraph"
    :class="[error && 'own-textarea--error', isEmpty && 'own-textarea--empty']"
    :name="accessibilityId"
    :rows="rows"
    :disabled="disabled"
    :placeholder="placeholder"
  ></textarea>
</template>

<script lang="ts" setup>
import { isDefined } from '@vueuse/core'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    accessibilityId?: string
    disabled?: boolean
    emptyAsNull?: boolean
    error?: boolean
    placeholder?: string
    rows?: string
    value?: string
  }>(),
  {
    accessibilityId: undefined,
    disabled: false,
    emptyAsNull: false,
    error: false,
    placeholder: undefined,
    rows: '5',
    value: undefined,
  }
)

const emit =
  defineEmits<(event: 'input', value: string | undefined | null) => void>()

const modelVal = computed({
  get() {
    return props.value
  },
  set(newVal) {
    if (props.emptyAsNull && newVal === '') {
      emit('input', null)
    } else {
      emit('input', newVal)
    }
  },
})

const isEmpty = computed(() => {
  return !isDefined(modelVal.value) || modelVal.value === ''
})
</script>

<style lang="scss">
@import '@/styles/utilities/padding';

.own-textarea {
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid $background-divider;

  background-color: $background-primary;
  color: $text-color-primary;

  @include control-shadow();

  transition:
    border 0.15s cubic-bezier(0.68, -0.04, 0.26, 0.87),
    color 0.15s cubic-bezier(0.68, -0.04, 0.26, 0.87),
    box-shadow 0.15s cubic-bezier(0.68, -0.04, 0.26, 0.87);

  &::placeholder {
    color: $text-color-placeholder;
  }

  &:disabled {
    cursor: not-allowed;
    color: $text-color-placeholder;

    &::placeholder {
      color: $text-color-placeholder;
    }
  }

  &--error {
    border-color: $status-danger !important;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: $background-highlight;
    border-color: rgba($misc-brand, 0.05);
  }
}
</style>
